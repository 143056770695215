import React from 'react'
import styles from './styles.module.css'
import Fade from 'react-reveal/Fade'

const ScanToPay = () => {
  return (
    <div>ScanToPay</div>
  )
}

export default ScanToPay